// import Masonry from "masonry-layout";

const tabs = document.querySelectorAll('.tabs')

tabs.forEach(tab => {
    const active = tab.querySelector('.tabs__item.is-active')
    const items = tab.querySelectorAll('.tabs__item')
    const bar = tab.querySelector('.tabs__active-bar');
    const tabsContent = tab.querySelectorAll('.tab-content');

    const changeTabsActive = (el) => {
        const activeTab = Number(el.dataset.tab);

        tab.querySelector('.tabs__item.is-active').classList.remove('is-active')
        el.classList.add('is-active')

        tabsContent.forEach(item => {
            const data = Number(item.dataset.tab)
            if (data === activeTab) {
                item.classList.add('is-active')

                // const msnry = new Masonry( item.querySelector('.grid'), {
                //     itemSelector: '.grid-item',
                //     gutter: 5,
                //     horizontalOrder: false,
                //     // isFitWidth : true,
                // });
            } else {
                item.classList.remove('is-active')
            }
        })

        const left = el.offsetLeft

        bar.style.width = `${el.offsetWidth  }px`
        bar.style.transform = `translateX(${left}px)`
    }

    changeTabsActive(active)

    items.forEach(item => {
        item.addEventListener('click', (e) => {
            changeTabsActive(e.target)
        })
    })
})
