import { gsap } from "gsap";

const videos = document.querySelectorAll('.video');


videos.forEach(item => {
    const btn = item.querySelector('.video__play-btn')
    const preview = item.querySelector('.video__preview')
    const title = item.querySelector('.video__title')
    const video = item.querySelector('video')

    if (!video) return false


    item.addEventListener('click', (e) => {

        gsap.to([preview, btn], {
            scale: 2,
            autoAlpha: 0,
        })

        gsap.to(title, {
            yPercent: 100,
            autoAlpha: 0,
            ease: "power1.out"
        })

        video.play();
        video.volume = 0.2;
    });

    return true
})

