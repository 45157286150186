import Swiper, { Pagination, FreeMode } from 'swiper';

Swiper.use([Pagination, FreeMode]);


const swiper = new Swiper('.swiper-list', {
    // slidesPerView: 1,
    slidesPerView: "auto",
    spaceBetween: 10,
    centeredSlides: false,
    speed: 700,
    preloadImages: false,
    slideToClickedSlide: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    breakpoints: {
        100: {
            // slidesPerView: 2,
            slidesPerView: "auto",
            spaceBetween: 0,
            freeMode: {
                enabled: true,
            },
            slidesOffsetAfter: 0,
            noSwiping: false,
            allowTouchMove: true
        },
        1001: {
            slidesPerView: "auto",
            spaceBetween: 0,
            centeredSlides: false,
            noSwiping: true,
            allowTouchMove: false
        }
    },
});
