// import Masonry from 'masonry-layout'
// import imagesLoaded from 'imagesloaded'
import lightGallery from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';
// import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgVideo from 'lightgallery/plugins/video';
// import lgMediumZoom from 'lightgallery/plugins/mediumZoom';
import lazyLoading from './components/lazyLoading'

import "./components/tabs";
import "./components/header"

import "./components/textarea-auto-resize"
import "./components/modal"
import "./components/calculator"
// import "./components/lang-switcher"
import "./components/form-check"
import "./components/select"
import "./components/video"
import "./components/slider-list"
import "./components/slider-reviews"
import "./components/input-floating-label"

import "./animations/landing/index"

lazyLoading.init()

document.querySelector('.loader-overlay').classList.remove('is-visible')

if (!process.env.DEV) {
    if (window.history.scrollRestoration) {
        window.history.scrollRestoration = 'manual';
    } else {
        window.onbeforeunload = () => {
            window.scrollTo(0, 0);
        }
    }
}

// const elems = document.querySelectorAll('.grid');
//
// elems.forEach(elem => {
//     const msnry = new Masonry( elem, {
//         itemSelector: '.grid-item',
//         gutter: 5,
//         horizontalOrder: false,
//         // isFitWidth : true,
//     });
//
//     imagesLoaded(msnry, () => {
//         msnry.layout();
//     });
// })


const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            const item = entry.target;
            item.classList.add('animation');

            observer.unobserve(item);
        }
    });
});

const animArr = document.querySelectorAll(`[class*="obj"]`);

animArr.forEach((n) => {
    observer.observe(n)
});

const galleries  = document.querySelectorAll('.grid')

const leftButton = `<svg width="37" height="15" viewBox="0 0 37 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M35.5 7.5L2 7.5M2 7.5L8.5 14M2 7.5L8.5 0.999999" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
</svg>`

const rightButton = `<svg width="36" height="15" viewBox="0 0 36 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 7.5L34.5 7.5M34.5 7.5L28 14M34.5 7.5L28 0.999999" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
</svg>`

const closeIcon = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.1527 10.8596L0.0764695 20.9359L0.783576 21.643L10.8598 11.5668L20.9361 21.643L21.6432 20.9359L11.5669 10.8596L21.6432 0.783363L20.9361 0.0762559L10.8598 10.1525L0.783579 0.0762843L0.0764722 0.783391L10.1527 10.8596Z" fill="white"/>
</svg>`

const zoomOut = `<svg width="23" height="1" viewBox="0 0 23 1" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.5C0 0.223858 0.223858 0 0.5 0H22.5C22.7761 0 23 0.223858 23 0.5C23 0.776142 22.7761 1 22.5 1H0.5C0.223858 1 0 0.776142 0 0.5Z" fill="white"/>
</svg>`

const zoomIn = `<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 0.5C12 0.223858 11.7761 0 11.5 0C11.2239 0 11 0.223858 11 0.5V11H0.5C0.223858 11 0 11.2239 0 11.5C0 11.7761 0.223858 12 0.5 12H11V22.5C11 22.7761 11.2239 23 11.5 23C11.7761 23 12 22.7761 12 22.5V12H22.5C22.7761 12 23 11.7761 23 11.5C23 11.2239 22.7761 11 22.5 11H12V0.5Z" fill="white"/>
</svg>`


galleries.forEach( (gallery) => {
    const itemGalleryLight = lightGallery(gallery, {
        // pager: false,
        plugins: [lgZoom],
        // addClass: "lg-title",
        selector: ".grid-item img",
        mobileSettings: {
            controls: false,
            showCloseIcon: true,
            rotate: false
        },
        showZoomInOutIcons: true,
        actualSize: false,
        mousewheel: true,
        download: false,
        prevHtml: leftButton,
        nextHtml: rightButton,
        counter: false,
        // appendSubHtmlTo: '.lg-item',
        // subHtmlSelectorRelative: false,
        // dynamicEl: itemGalleryDynamic,
        // plugins: [lgMediumZoom],
        margin: 10
    });

    const $container = itemGalleryLight.$container.selector

    $container.querySelector('.lg-close').insertAdjacentHTML("beforeend", closeIcon);
    $container.querySelector('.lg-zoom-in').insertAdjacentHTML("beforeend", zoomIn);
    $container.querySelector('.lg-zoom-out').insertAdjacentHTML("beforeend", zoomOut);
})

const patentSel = document.querySelector('.patent')

const patentGl = lightGallery(patentSel, {
    // pager: false,
    plugins: [lgZoom],
    // addClass: "lg-title",
    selector: 'this',
    mobileSettings: {
        controls: false,
        showCloseIcon: true,
        rotate: false
    },
    showZoomInOutIcons: true,
    actualSize: false,
    mousewheel: true,
    download: false,
    prevHtml: leftButton,
    nextHtml: rightButton,
    counter: false,
    getCaptionFromTitleOrAlt: false,
    margin: 10
});

const $container = patentGl.$container.selector

$container.querySelector('.lg-close').insertAdjacentHTML("beforeend", closeIcon);
$container.querySelector('.lg-zoom-in').insertAdjacentHTML("beforeend", zoomIn);
$container.querySelector('.lg-zoom-out').insertAdjacentHTML("beforeend", zoomOut);


const patent2Gl = lightGallery(document.querySelector('.border-block__hidden'), {
    // pager: false,
    plugins: [lgZoom],
    // addClass: "lg-title",
    selector: '.border-block__hidden-item',
    mobileSettings: {
        controls: false,
        showCloseIcon: true,
        rotate: false
    },
    showZoomInOutIcons: true,
    actualSize: false,
    mousewheel: true,
    download: false,
    prevHtml: leftButton,
    nextHtml: rightButton,
    counter: false,
    getCaptionFromTitleOrAlt: false,
    margin: 10
});

document.querySelector('.border-block')?.addEventListener('click', (e) => {
    patent2Gl.openGallery(0);
});

const $container2 = patent2Gl.$container.selector

$container2.querySelector('.lg-close').insertAdjacentHTML("beforeend", closeIcon);
$container2.querySelector('.lg-zoom-in').insertAdjacentHTML("beforeend", zoomIn);
$container2.querySelector('.lg-zoom-out').insertAdjacentHTML("beforeend", zoomOut);


const reviewsGl = lightGallery(document.querySelector('.swiper-reviews'), {
    // pager: false,
    plugins: [lgVideo],
    // addClass: "lg-title",
    selector: '.swiper-slide',
    mobileSettings: {
        controls: false,
        showCloseIcon: true,
        rotate: false
    },
    mousewheel: true,
    download: false,
    prevHtml: leftButton,
    nextHtml: rightButton,
    counter: false,
    getCaptionFromTitleOrAlt: false,
    margin: 10
});

reviewsGl.$container.selector.querySelector('.lg-close').insertAdjacentHTML("beforeend", closeIcon);
reviewsGl.$container.selector.querySelector('.lg-zoom-in').insertAdjacentHTML("beforeend", zoomIn);
reviewsGl.$container.selector.querySelector('.lg-zoom-out').insertAdjacentHTML("beforeend", zoomOut);
