import { gsap } from "gsap";

// const selects = document.querySelectorAll('.js-selectNative')
const selectProducts = document.querySelectorAll('.js-select-product')

selectProducts.forEach(selectProduct => {
    const section = selectProduct.closest('.section')
    const imgWrap = section.querySelector('.section-personal__img-wrapper img')
    const text = section.querySelector('.section-personal__wrapper .col--sec p')
    const hiddenGroup = section.querySelector('.select-group')
    const selectsHidden = hiddenGroup.querySelectorAll('.select, p')
    const selectColors = section.querySelector('.js-select-color')

    selectColors.addEventListener("change", (e) => {
        const { value, name } = e.target;

        window.colors.forEach((item) => {
            if (item.color === value) {
                imgWrap.src = item.img
                text.innerText = item.color
            }
        })
    })

    selectProduct.addEventListener("change", (e) => {
        const { value, name } = e.target;

        window.products.forEach((item) => {
            if (item.val === value) {
                imgWrap.src = item.img
                text.innerText = item.title
            }
        })

        if (value === 'leather') {

            window.colors.forEach((item) => {
                if (item.color === selectColors.value) {
                    imgWrap.src = item.img
                    text.innerText = item.color
                }
            })

            gsap.to(hiddenGroup, {
                duration: .45,
                height: 'auto',
                ease: "power1.out",
            })

            gsap.to(selectsHidden, {
                duration: .45,
                autoAlpha: 1,
                y: 0,
                stagger: .15,
                ease: "power1.out",
            })
        } else {
            gsap.to(hiddenGroup, {
                duration: .45,
                height: 0,
                ease: "power1.out",
            })

            gsap.to(selectsHidden, {
                duration: .25,
                autoAlpha: 0,
                y: 25,
                stagger: -.12,
                ease: "power1.out",
            })
        }
    });
})


