/* eslint-disable no-use-before-define */
import { gsap } from "gsap";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
// import { closeLangSwitcher } from "./lang-switcher"

const header = document.querySelector(".header");

const hamburger = document.querySelector(".hamburger");
// const hamburgerLines = hamburger.querySelectorAll(".hamburger__line");
const hamburgerTrigger = document.querySelector(".header__hamburger");
const mobMenu = document.querySelector(".mobile-menu");

const tlMobileMenu = gsap.timeline({ paused: true });

tlMobileMenu
    .to(mobMenu, {
        autoAlpha: 1
    }, 0)
    .fromTo(".mobile-menu .nav__item span, .mobile-menu__tel, .mobile-menu__user", {
        yPercent: 100,
        autoAlpha: 0
    }, {
        autoAlpha: 1,
        yPercent: 0,
        duration: .8,
        ease: "expo.out",
        stagger: .08
    }, .2)

function runmenu(run) {
    if (run === true) {
        tlMobileMenu.timeScale(1).progress(0).play();
        header.classList.add('is-active');
        hamburger.classList.add('is-active');
        mobMenu.classList.add('is-active');
        disableBodyScroll(mobMenu);
    } else {
        tlMobileMenu.timeScale(1.5).reverse();
        header.classList.remove('is-active');
        hamburger.classList.remove('is-active');
        mobMenu.classList.remove('is-active');
        enableBodyScroll(mobMenu);
    }
}

if (hamburgerTrigger) {
    hamburgerTrigger.addEventListener("click", () => {
        runmenu(!hamburger.classList.contains('is-active'));
    });
}

const mobMenuNavItems = mobMenu.querySelectorAll('.mobile-menu__nav a');
mobMenuNavItems.forEach(item => {
    item.addEventListener("click", () => {
        runmenu(false)
    });
})


let lastScrollTop = 0;
let ticking = false;
const isLanding = window.location.pathname === '/'

if (!isLanding) {
    header.classList.add('header--bg');
}

const stickyHeader = (scroll) => {
    if (header.classList.contains('is-animations')) {
        return
    }

    const { height: headerHeight } = header.getBoundingClientRect()

    if (scroll <= 0) {
        header.classList.remove('is-fixed')
        return;
    }

    if (scroll > 5) {
        header.classList.add('is-fixed');
    } else {
        header.classList.remove('is-fixed');
    }

    if (scroll > lastScrollTop && scroll > headerHeight * 5) {
        header.classList.remove('is-visible');
        // closeLangSwitcher()

        // header.classList.add('is-fixed');
    } else if (scroll < lastScrollTop) {
        header.classList.add('is-visible');
        // header.classList.remove('is-fixed');
    }

    lastScrollTop = scroll;
}


document.addEventListener("scroll", () => {
    const scroll = window.scrollY;

    if (!ticking) {
        window.requestAnimationFrame(() => {
            stickyHeader(scroll);

            setTimeout(() => {
                ticking = false;

                if (window.scrollY <= 0) {
                    header.classList.remove('is-fixed')
                }
            }, 200)
        });

        ticking = true;
    }
})

