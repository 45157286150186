import Swiper, { Navigation, Pagination, FreeMode } from 'swiper';

Swiper.use([Navigation, Pagination, FreeMode]);


const swiper = new Swiper('.swiper-reviews', {
    // slidesPerView: 1,
    slidesPerView: "auto",
    spaceBetween: 0,
    centeredSlides: false,
    speed: 700,
    preloadImages: false,
    slideToClickedSlide: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    freeMode: {
        enabled: true,
    },
    navigation: {
        nextEl: '.swiper-custom-button-next',
        prevEl: '.swiper-custom-button-prev',
    }
});
