import lozad from 'lozad'

let observer;

const init = () => {
    observer = lozad('.js-lazy', {
        rootMargin: '100px 0px',
        enableAutoReload: true,
        loaded(el) {
            el.classList.add('is-loaded');
        },
    })

    observer.observe();
}

const trigger = (img) => {
    observer.triggerLoad(img);
};

export default {
    init,
    trigger,
};
