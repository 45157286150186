const inputs = document.querySelectorAll('.js-tel-check')

if (inputs.length) {
    inputs.forEach(input => {
        input.addEventListener('input', () => {
            const regex2 = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/

            if (input.value.match(regex2) && !(input.value.match(/0{5,}/))) {
                // if (!input.value.match(regex2)) {
                const parent = input.closest('.input-field-container')
                parent.querySelector('.input-field-helper-line')?.remove()
                input.classList.remove('is-error')
            } else {
                const parent = input.closest('.input-field-container')
                parent.querySelector('.input-field-helper-line')?.remove()

                input.classList.add('is-error')
                parent.insertAdjacentHTML('beforeend',
                    `<div class="input-field-helper-line">
                            <div class="input-field-helper-text is-danger">
                              Введите корректные данные
                            </div>
                          </div>`
                )
            }

            if (input.value.length === 0) {
                const parent = input.closest('.input-field-container')
                parent.querySelector('.input-field-helper-line')?.remove()
                input.classList.remove('is-error')
            }

            /* const regex = /[^0-9]/
            console.log(input.value.length);
            if (!input.value.length) return

            if (input.value.match(regex)) {
                const parent = input.closest('.input-field-container')
                parent.querySelector('.input-field-helper-line')?.remove()
                // input-field-helper-line
                parent.insertAdjacentHTML('beforeend',
                    `<div class="input-field-helper-line">
                            <div class="input-field-helper-text is-danger">
                              The number is incorrect
                            </div>
                          </div>`
                )
            } else {
                const parent = input.closest('.input-field-container')
                parent.querySelector('.input-field-helper-line')?.remove()
            } */
        })
    })
}

const validateEmail = (email) => String(email)
    .toLowerCase()
    .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

const inputsEmail = document.querySelectorAll('input[type="email"]')

if (inputsEmail.length) {
    inputsEmail.forEach(input => {
        input.addEventListener('input', () => {
            if (validateEmail(input.value)) {
                input.classList.remove('is-error')
            } else {
                input.classList.add('is-error')
            }
        })
    })
}

const forms = document.querySelectorAll('form')

const validate = (field) => {
    if(field.type === 'tel' && field.classList.contains('is-error')) {
        return false
    }

    if (field.classList.contains('textarea')) {
        return !field.classList.contains('is-error');
    }


    if(field.type === 'tel' && field.classList.contains('is-error')) {
        return false
    }

    if(field.type === 'email' && field.classList.contains('is-error')) {
        return false
    }

    if (!field.value?.trim()) {
        return false
    }

    if (field.textContent.trim().length > 0) {

        return false
    }

    return true
}

if (forms.length) {
    forms.forEach(form => {
        const formData = new FormData(form);
        const formProps = Object.fromEntries(formData);
        const fields = form.querySelectorAll('input:not([type="hidden"]), .textarea');
        const sumbit = form.querySelector('.form__submit button')
        let error = false

        if (!sumbit) return

        fields.forEach(field => {

            if (!validate(field)) {
                error = true
            }

            field.addEventListener('input', event => {
                error = false

                fields.forEach(item => {
                    if (!validate(item)) {
                        error = true
                    }
                })

                if (!error) {
                    sumbit.disabled = false
                } else {
                    sumbit.disabled = true
                }
            })
        })

        if (!error) {
            sumbit.disabled = false
        } else {
            sumbit.disabled = true
        }

        form.addEventListener('submit', (e) => {
            e.target.querySelectorAll('.input-field-helper-line').forEach(item => {
                item.remove()
            })
        })
    })
}


const sensorForms = document.querySelectorAll('.js-sensor-form')

sensorForms.forEach(sensorForm => {
    sensorForm.addEventListener('submit', (e) => {
        e.preventDefault()

        const formData = new FormData(sensorForm);

        const $comment = sensorForm.querySelector('.textarea').innerText;

        sensorForm.querySelector('.form-result')?.remove()

        formData.append( 'action', 'ajax_form' );
        formData.append( 'comment', $comment );

        fetch('/wp-admin/admin-ajax.php', {
            method: 'POST',
            body: formData
        }).then(async (response) => {
            const json =  await response.json()

            if (response.ok) {
                const msg = `<div class="form-result">${json.msg}</div>`

                sensorForm.insertAdjacentHTML('beforeend', msg)

                const container = sensorForm.closest('.modal-container')

                if (container) {
                    container.querySelector('.modal-close').click()
                }

                const modl = document.querySelector('#modal-accepted');

                if (modl) {
                    modl.querySelector('.modal__title').textContent = json.title
                    modl.querySelector('.modal__desc').textContent = json.msg
                }

                sensorForm.querySelector('.form__hidden').click()

                return response.json();
            }

            const msg = `<div class="form-result is-error">${json.msg}</div>`

            sensorForm.insertAdjacentHTML('beforeend', msg)

            Object.keys(json.errors).forEach(field => {
                const el = sensorForm.querySelector(`[name=${field}]`)
                // const input = el.querySelector('input')

                const parent = el.closest('.input-field-container')
                parent.querySelector('.input-field-helper-line')?.remove()

                // input.classList.add('is-error')
                parent.insertAdjacentHTML('beforeend',
                    `<div class="input-field-helper-line">
                            <div class="input-field-helper-text is-danger">
                              ${json.errors[field]}
                            </div>
                          </div>`
                )
            })

            const err = new Error("Not 2xx response");
            err.response = response;
            throw err;

        }).catch((err) => {
            // There was an error
            console.warn('Something went wrong.', err);
        });

    })
})

const questionForms = document.querySelectorAll('.js-question-form')

questionForms.forEach(questionForm => {
    questionForm.addEventListener('submit', (e) => {
        e.preventDefault()

        const formData = new FormData(questionForm);

        const $comment = questionForm.querySelector('.textarea').innerText;

        questionForm.querySelector('.form-result')?.remove()

        formData.append('action', 'ajax_form_question');
        formData.append('comment', $comment);

        fetch('/wp-admin/admin-ajax.php', {
            method: 'POST',
            body: formData
        }).then(async (response) => {
            const json = await response.json()

            if (response.ok) {
                const msg = `<div class="form-result">${json.msg}</div>`

                questionForm.insertAdjacentHTML('beforeend', msg)


                const container = questionForm.closest('.modal-container')

                if (container) {
                    container.querySelector('.modal-close').click()
                }

                const modl = document.querySelector('#modal-accepted');

                if (modl) {
                    modl.querySelector('.modal__title').textContent = json.title
                    modl.querySelector('.modal__desc').textContent = json.msg
                }

                questionForm.querySelector('.form__hidden').click()

                return response.json();
            }

            const msg = `<div class="form-result is-error">${json.msg}</div>`

            questionForm.insertAdjacentHTML('beforeend', msg)

            Object.keys(json.errors).forEach(field => {
                const el = questionForm.querySelector(`[name=${field}]`)
                // const input = el.querySelector('input')

                const parent = el.closest('.input-field-container')
                parent.querySelector('.input-field-helper-line')?.remove()

                // input.classList.add('is-error')
                parent.insertAdjacentHTML('beforeend',
                    `<div class="input-field-helper-line">
                            <div class="input-field-helper-text is-danger">
                              ${json.errors[field]}
                            </div>
                          </div>`
                )
            })

            const err = new Error("Not 2xx response");
            err.response = response;
            throw err;

        }).catch((err) => {
            // There was an error
            console.warn('Something went wrong.', err);
        });

    })
})

const partnerForms = document.querySelectorAll('.js-partner-form')

partnerForms.forEach(partnerForm => {
    partnerForm.addEventListener('submit', (e) => {
        e.preventDefault()

        const formData = new FormData(partnerForm);

        const $comment = partnerForm.querySelector('.textarea').innerText;

        partnerForm.querySelector('.form-result')?.remove()

        formData.append('action', 'ajax_form_partner');
        formData.append('comment', $comment);

        fetch('/wp-admin/admin-ajax.php', {
            method: 'POST',
            body: formData
        }).then(async (response) => {
            const json = await response.json()

            if (response.ok) {
                const msg = `<div class="form-result">${json.msg}</div>`

                partnerForm.insertAdjacentHTML('beforeend', msg)


                const container = partnerForm.closest('.modal-container')

                if (container) {
                    container.querySelector('.modal-close').click()
                }

                const modl = document.querySelector('#modal-accepted');

                if (modl) {
                    modl.querySelector('.modal__title').textContent = json.title
                    modl.querySelector('.modal__desc').textContent = json.msg
                }

                partnerForm.querySelector('.form__hidden').click()

                return response.json();
            }

            const msg = `<div class="form-result is-error">${json.msg}</div>`

            partnerForm.insertAdjacentHTML('beforeend', msg)

            Object.keys(json.errors).forEach(field => {
                const el = partnerForm.querySelector(`[name=${field}]`)
                // const input = el.querySelector('input')

                const parent = el.closest('.input-field-container')
                parent.querySelector('.input-field-helper-line')?.remove()

                // input.classList.add('is-error')
                parent.insertAdjacentHTML('beforeend',
                    `<div class="input-field-helper-line">
                            <div class="input-field-helper-text is-danger">
                              ${json.errors[field]}
                            </div>
                          </div>`
                )
            })

            const err = new Error("Not 2xx response");
            err.response = response;
            throw err;

        }).catch((err) => {
            // There was an error
            console.warn('Something went wrong.', err);
        });

    })
})

