import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

const heroTl = gsap.timeline({
    delay: .5
})

heroTl
    .fromTo('.header__wrap > *, .nav__item', {
        y: -100,
        autoAlpha: 0,
    }, {
        duration: .75,
        y: 0,
        autoAlpha: 1,
        stagger: .05,
        ease: "power1.out"
    }, 0)
    .fromTo('.hero__wrapper > *', {
        xPercent: -100,
        autoAlpha: 0,
    }, {
        duration: .95,
        xPercent: 0,
        autoAlpha: 1,
        stagger: .1,
        ease: "power1.out"
    }, .3)
    .fromTo('.hero__bg img', {
        scale: 1.5,
        autoAlpha: 0,
    }, {
        duration: .75,
        scale: 1,
        autoAlpha: 1,
        ease: "power1.out"
    }, 0)

gsap.fromTo('.section-protection__video', {
    yPercent: 100,
    autoAlpha: 0
}, {
    duration: 1.2,
    yPercent: 0,
    autoAlpha: 1,
    ease: "power1.out",
    scrollTrigger: {
        trigger: '.section-protection__content',
        start: 'top 90%',
        end: 'bottom 10%',
        toggleActions: "play",
        // markers: true
    },
})

gsap.fromTo('.section-protection__content > *', {
    xPercent: -100,
    autoAlpha: 0
}, {
    duration: 1.2,
    xPercent: 0,
    autoAlpha: 1,
    stagger: .15,
    ease: "power1.out",
    scrollTrigger: {
        trigger: '.section-protection__content',
        start: 'top 90%',
        end: 'bottom 10%',
        toggleActions: "play",
        // markers: true
    },
})

const workTl = gsap.timeline({
    defaults: {
        duration: 1.2
    },
    scrollTrigger: {
        trigger: '.section-work',
        start: 'top 80%',
        end: 'bottom 10%',
        toggleActions: "play",
        // markers: true
    },
})

workTl
    .fromTo('.patent', {
        xPercent: -100,
        autoAlpha: 0
    }, {
        xPercent: 0,
        autoAlpha: 1,
        ease: "power1.out",
    }, 0)
    .fromTo('.patent__title', {
        yPercent: 100
    }, {
        yPercent: 0,
        ease: "power1.out",
    }, 0)
    .fromTo('.section-work .list-icon', {
        y: 40,
        autoAlpha: 0
    }, {
        duration: .4,
        autoAlpha: 1,
        y: 0,
        stagger: .15,
        ease: "power1.out",
    }, .03)
    .fromTo('.section-work__gradient-block', {
        xPercent: 100
    }, {
        xPercent: 0,
        ease: "power1.out",
    }, .15)


// const firstActive = false

/* ScrollTrigger.create({
    trigger: '.section-gallery',
    start: 'top 60%',
    end: 'bottom 70%',
    toggleActions: "play",
    markers: true,

    onEnter: () => {
        if (firstActive) return

        const galleryTl = gsap.timeline({
            defaults: {
                duration: 1.3
            },
        })

        galleryTl
            .to('.section-gallery .section-title, .section-gallery .tabs__header', {
                autoAlpha: 1,
                xPercent: 0,
                yPercent: 0,
                ease: "power1.out",
            }, 0)
            .to('.section-gallery .tab-content.is-active .grid-item',{
                autoAlpha: 1,
                xPercent: 0,
                yPercent: 0,
                stagger: 0.08,
                ease: "power1.inOut",
            }, 1)

        firstActive = true
    },

    onEnterBack: () => {
        const galleryTlLeave = gsap.timeline({
            defaults: {
                duration: .8
            }
        })

        galleryTlLeave
            .to('.section-gallery .section-title, .section-gallery .tabs__header', {
                yPercent: 0,
                autoAlpha: 1,
                ease: "power1.out",
            }, 0)
            .to('.section-gallery .tab-content.is-active .grid-item', {
                xPercent: 0,
                autoAlpha: 1,
                yPercent: 0,
                ease: "power1.out",
            }, 0)

    },

    onLeave: () => {

        const galleryTlLeave = gsap.timeline({
            defaults: {
                duration: 1.2
            }
        })

        galleryTlLeave
            .to('.section-gallery .section-title, .section-gallery .tabs__header', {
                yPercent: -300,
                autoAlpha: 0,
                ease: "power1.out",
            }, 0)
            .to('.section-gallery .tab-content.is-active .grid-item', {
                autoAlpha: 0,
                xPercent: (index) => {
                    if (index === 0) {
                        return -100
                    }

                    if (index === 1) {
                        return 10
                    }

                    if (index === 2) {
                        return 25
                    }

                    if (index === 3) {
                        return 100
                    }

                    if (index === 4) {
                        return -30
                    }

                    if (index === 5) {
                        return -5
                    }

                    if (index === 6) {
                        return 15
                    }

                    return 42
                },
                yPercent: (index) => {
                    if (index === 0) {
                        return -1000
                    }

                    if (index === 1) {
                        return -100
                    }

                    if (index === 2) {
                        return -250
                    }

                    if (index === 3) {
                        return -1000
                    }

                    if (index === 4) {
                        return -300
                    }

                    if (index === 5) {
                        return -50
                    }

                    if (index === 6) {
                        return -150
                    }

                    return -420
                },
                ease: "power1.inOut",
            }, 0)
    }
}) */

ScrollTrigger.matchMedia({
    "(min-width: 600px)": () => {

        gsap.set('.section-gallery .section-title, .section-gallery .tabs__header', {
            // xPercent: -30,
            y: 60,
            autoAlpha: 0,
        })

        gsap.set('.section-gallery .tab-content.is-active .grid-item', {
            autoAlpha: 0,
            xPercent: (index) => {
                if ([0, 1, 4, 5].includes(index)) {
                    return -50
                }

                if ([1, 5].includes(index)) {
                    return -10
                }

                if ([3, 6].includes(index)) {
                    return 15
                }

                return 42
            },
            yPercent: (index) => {
                if (index === 0) {
                    return 1000
                }

                if (index === 1) {
                    return 100
                }

                if (index === 2) {
                    return 250
                }

                if (index === 3) {
                    return 1000
                }

                if (index === 4) {
                    return 300
                }

                if (index === 5) {
                    return 50
                }

                if (index === 6) {
                    return 150
                }

                return 420
            },
        })

        const galleryTl = gsap.timeline({
            defaults: {
                duration: 1.3
            },
            scrollTrigger: {
                trigger: '.section-gallery',
                start: 'top 90%',
                end: 'bottom 90%',
                toggleActions: "play",
                // markers: true,
                scrub: 2,
            }
        })

        galleryTl
            .to('.section-gallery .section-title, .section-gallery .tabs__header', {
                autoAlpha: 1,
                xPercent: 0,
                y: 0,
                ease: "power1.out",
            }, 0)
            .to('.section-gallery .tab-content.is-active .grid-item',{
                autoAlpha: 1,
                xPercent: 0,
                yPercent: 0,
                stagger: 0.08,
                ease: "power1.inOut",
            }, .2)

        // const galleryTlLeave = gsap.timeline({
        //     defaults: {
        //         duration: 1.2
        //     },
        //     scrollTrigger: {
        //         trigger: '.section-gallery',
        //         start: 'bottom 55%',
        //         end: '+=800 70%',
        //         toggleActions: "play",
        //         // markers: true,
        //         scrub: true
        //     }
        // })
        //
        // let count = -1;
        // let count2 = -1;
        //
        // galleryTlLeave
        //     .to('.section-gallery .section-title, .section-gallery .tabs__header', {
        //         yPercent: -300,
        //         autoAlpha: 0,
        //         ease: "power1.out",
        //     }, 0)
        //     .to('.section-gallery .tab-content .grid-item', {
        //         autoAlpha: 0,
        //         xPercent: (index) => {
        //             if (count > 7) {
        //                 count = -1;
        //             }
        //
        //             count += 1
        //
        //             console.log(count);
        //
        //             if (count === 0) {
        //                 return -100
        //             }
        //
        //             if (count === 1) {
        //                 return 10
        //             }
        //
        //             if (count === 2) {
        //                 return 25
        //             }
        //
        //             if (count === 3) {
        //                 return 100
        //             }
        //
        //             if (count === 4) {
        //                 return -30
        //             }
        //
        //             if (count === 5) {
        //                 return -5
        //             }
        //
        //             if (count === 6) {
        //                 return 15
        //             }
        //
        //             return 42
        //         },
        //         yPercent: (index) => {
        //             if (count2 > 7) {
        //                 count2 = -1;
        //             }
        //
        //             count2 += 1
        //
        //             if (count2 === 0) {
        //                 return -1000
        //             }
        //
        //             if (count2 === 1) {
        //                 return -100
        //             }
        //
        //             if (count2 === 2) {
        //                 return -250
        //             }
        //
        //             if (count2 === 3) {
        //                 return -1000
        //             }
        //
        //             if (count2 === 4) {
        //                 return -300
        //             }
        //
        //             if (count2 === 5) {
        //                 return -50
        //             }
        //
        //             if (count2 === 6) {
        //                 return -150
        //             }
        //
        //             return -420
        //         },
        //         ease: "power1.inOut",
        //     }, 0)
    },
    "(max-width: 599px)": () => {
        const galleryTl = gsap.timeline({
            defaults: {
                duration: 1.3
            },
            scrollTrigger: {
                trigger: '.section-gallery',
                start: 'top 90%',
                end: 'bottom 100%',
                toggleActions: "play",
            }
        })

        galleryTl
            .fromTo('.section-gallery .section-title, .section-gallery .tabs__header', {
                y: 16,
                autoAlpha: 0,
            },{
                y: 0,
                autoAlpha: 1,
                ease: "power1.out",
            }, 0)
            .fromTo('.section-gallery .tab-content.is-active .grid-item', {
                y: 16,
                autoAlpha: 0,
            },{
                autoAlpha: 1,
                y: 0,
                stagger: 0.15,
                ease: "power1.inOut",
            }, 0)
    }
})


gsap.utils.toArray('.img-wrap').forEach(el => {
    const img = el.querySelector('img')

    gsap.set(el, {
        overflow: "hidden"
    })

    gsap.fromTo(img, {
        scale: 1.5,
        transformOrigin: "50% 50%",
    }, {
        duration: 1.2,
        scale: 1,
        transformOrigin: "50% 50%",
        ease: "power1.out",

        scrollTrigger: {
            trigger: el,
            start: 'top 90%',
            end: 'bottom 10%',
            toggleActions: "play",
            // markers: true
        },
    });
})

gsap.utils.toArray('.section-characteristics').forEach(el => {
    const imgBlock = el.querySelector('.section-characteristics__img')
    const imgWrap = el.querySelector('.section-characteristics__img-wrapper')
    const img = imgWrap.querySelector('img')

    gsap.set([el, imgWrap], {
        overflow: "hidden"
    })

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: el,
            start: 'top 80%',
            end: '50% 10%',
            toggleActions: "play",
            onEnter: () => {
                ScrollTrigger.refresh()
            }
        }
    })

    tl
        .from(imgBlock, {
            duration: 1.6,
            xPercent: 100,
            ease: "power1.out",
        }, 0)
        .from(img, {
            duration: 1.8,
            scale: 2,
            transformOrigin: "50% 50%",
            ease: "power1.inOut",
        }, 0);
})

const tl = gsap.timeline({
    scrollTrigger: {
        trigger: '.js-personal-trigger',
        start: 'top 80%',
        end: '50% 10%',
        toggleActions: "play",
    }
})

tl
    .fromTo('.js-personal-trigger .col--first > *', {
        autoAlpha: 0,
        xPercent: -100,
    },{
        duration: 1.2,
        autoAlpha: 1,
        xPercent: 0,
        stagger: .15,
        ease: "power1.out",
    }, 0)
    .fromTo('.js-personal-trigger .col--sec', {
        y: 16,
    },{
        duration: .5,
        y: 0,
        ease: "power1.out",
    }, 0)
    .fromTo('.js-personal-trigger .col--last .form > *', {
        autoAlpha: 0,
        xPercent: 100,
    },{
        duration: 1.2,
        autoAlpha: 1,
        xPercent: 0,
        stagger: .15,
        ease: "power1.out",
    }, 0)

const tlp = gsap.timeline({
    scrollTrigger: {
        trigger: '.partner',
        start: 'top 95%',
        end: '50% 10%',
        toggleActions: "play",
    }
})

tlp
    .fromTo(".partner", {
        y: 50,
    }, {
        y: 0,
        duration: 1,
        ease: "power1.out"
    }, 0)
    .fromTo(".partner .col > *", {
        y: 80,
    }, {
        y: 0,
        stagger: 0.08,
        duration: 1.2,
        ease: "power1.out"
    }, 0)
    .fromTo(".partner-btn__circle-wrap", {
        scale: .5,
    }, {
        scale: 1,
        transformOrigin: "0 50%",
        duration: 1,
        ease: "power1.out",
        /* onComplete: () => {
            gsap.set('.partner-btn__circle', {
                className: 'partner-btn__circle animation'
            })
        } */
    }, .5)
